@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {
    .scrollbar::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
  
    .scrollbar::-webkit-scrollbar-track {
      border-radius: 100vh;
   
    }
  
    .scrollbar::-webkit-scrollbar-thumb {
      border-radius: 100vh;
      border: 3px solid #4B5563;
    }
  }
